export const BSC = 56
export const OPTIMISM = 10
export const BNBCHAIN = 56

export const addresses = {
    [BSC]: {
        Vault: "0xc73A8DcAc88498FD4b4B1b2AaA37b0a2614Ff67B",
        Router: "0xD46B23D042E976F8666F554E928e0Dc7478a8E1f",
        USDG: "0x85E76cbf4893c1fbcB34dCF1239A91CE2A4CF5a7",
        Stabilize: "0x82C4841728fBd5e08A77A95cA3192BcE1F645Ee9",
        WardenSwapRouter: "0x7A1Decf6c24232060F4D76A33a317157549C2093",
        OneInchRouter: "0x11111112542D85B3EF69AE05771c2dCCff4fAa26",
        DodoexRouter: "0x8F8Dd7DB1bDA5eD3da8C9daf3bfa471c12d58486",
        MetamaskRouter: "0x1a1ec25DC08e98e5E93F1104B5e5cdD298707d31"
    },

    [OPTIMISM]: {
        GMX: '0xcdb4bb51801a1f399d4402c61bc098a72c382e65',
        BTC: '0x68f180fcce6836688e9084f035309e29bf0a2095',
        ETH: '0x4200000000000000000000000000000000000006',
        OP: '0x4200000000000000000000000000000000000042',
        LINK: '0x350a791bfc2c21f9ed5d10980dad2e2638ffa7f6',
        UNI: '0x6fd9d7ad17242c41f7131d257212c54a0e816691',
        DAI: '0xda10009cbd5d07dd0cecc66161fc93d7c9000da1',
        RewardReader: '0x991caA629EAe2945a17e55780bF7B4fA48f36975',
        GLP: '0xF844BB34ec0690093972936bA7084A6dE51F11bB',
        GlpManager: '0x102F3871561921517bdaaec3cF5eF9C8c530AA21'
    },

    [BNBCHAIN]: {
        GMX: '0xe88e2502ffced29e7ee2aac37a318afaf447dbe3',
        BNB: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
        ETH: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
        BTC: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
        RewardReader: '0x33ae89e2e7712C9A09c3957Cd6e1F0D69C106Ce8',
        GLP: '0x974B7EE50982a57681daB70f5fe2041F415143a4',
        GlpManager: '0x642B0d81E3929b42F56B852b5f5756A804F8C8b2'
    }
}

export function getAddress(chainId, key) {
    if (!(chainId) in addresses) {
        throw new Error(`Unknown chain ${chainId}`)
    }
    if (!(key in addresses[chainId])) {
        throw new Error(`Unknown address key ${key}`)
    }
    return addresses[chainId][key]
}
